import React from "react";
import styled from 'styled-components'

import GoogleMapReact from 'google-map-react';

import { Container, Row, Col } from 'styled-bootstrap-grid';


const Wrapper = styled.div`
  background-color: ${props => props.bgColor || "#232323"};
  margin-top: 64px;
  height: 420px;
  width: 100%
`;

const Marker = styled.div`
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  height: 3rem;
  position: absolute;
  width: 3rem;
  left: calc(-3rem / 2);
  top: -3rem;

  color: #000;
  text-align: center;
`;

const Map = ({ props }) => {

  const google = props;

  // const imageUrl =
  //   process.env.NODE_ENV !== "development"
  //     ? props.marker.url
  //     : process.env.REACT_APP_BACKEND_URL + props.marker.url;

  function createMapOptions(maps) {
    // next props are exposed at maps
    // "Animation", "ControlPosition", "MapTypeControlStyle", "MapTypeId",
    // "NavigationControlStyle", "ScaleControlStyle", "StrokePosition", "SymbolPath", "ZoomControlStyle",
    // "DirectionsStatus", "DirectionsTravelMode", "DirectionsUnitSystem", "DistanceMatrixStatus",
    // "DistanceMatrixElementStatus", "ElevationStatus", "GeocoderLocationType", "GeocoderStatus", "KmlLayerStatus",
    // "MaxZoomStatus", "StreetViewStatus", "TransitMode", "TransitRoutePreference", "TravelMode", "UnitSystem"
    return {
      zoomControlOptions: {
        position: maps.ControlPosition.RIGHT,
        style: maps.ZoomControlStyle.SMALL
      },
      mapTypeControlOptions: {
        position: false
      },
      mapTypeControl: false,
      styles: google.style
    };
  }

  return (
    <Container>
      <Row>
        <Col col xl="12" lg="12" md="12" sm="12" xs="12">
          <Wrapper>
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyAS2sBXz6uH6rhOSlOvg7gXuB37xpkBss0" }}
              defaultCenter={{
                lat: google.latitude,
                lng: google.longitude
              }}
              defaultZoom={google.zoom}
              yesIWantToUseGoogleMapApiInternals
              options={createMapOptions}
            >
            {google.marker && (
              <Marker
                lat={google.latitude}
                lng={google.longitude}
                style={{backgroundImage: `url(${process.env.NODE_ENV !== "development"
                  ? google.marker.url
                  : process.env.REACT_APP_BACKEND_URL + google.marker.url})`}}
              />
            )}
            </GoogleMapReact>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
}

export default Map;
