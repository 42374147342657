import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const Image = styled.img`
  height: auto;
  width: calc(100% + 32px);

  margin: 0 -16px -3px;
`;

const FullImage = ({ props }) => {

  return (
    <Container fluid>
      <Row>
        <Col col={12}>
          <Image
            src={process.env.NODE_ENV !== "development" ? props.image.url : process.env.REACT_APP_BACKEND_URL + props.image.url}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default FullImage;
