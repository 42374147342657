import React from "react";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';

import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

import { CgChevronLeft } from 'react-icons/cg';
import { CgChevronRight } from 'react-icons/cg';

const Wrapper = styled.div`
  margin: 36px 0;
  position: relative;
  z-index: 0;
`;

const PrevArrow = styled.div`
  left: 1rem;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  font-size: 32px;
  cursor: pointer;
`;

const NextArrow = styled.div`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;

  font-size: 32px;
  cursor: pointer;
`;

const Slider = ({ props }) => {

  return (
    <Container>
      <Row>
        <Col>
          <Wrapper>
            <Carousel
              renderArrowPrev={(onClickHandler, hasPrev, label) =>
                   hasPrev && (
                     <PrevArrow onClick={onClickHandler} >
                      <CgChevronLeft />
                     </PrevArrow>
                   )
               }
               renderArrowNext={(onClickHandler, hasNext, label) =>
                   hasNext && (
                     <NextArrow onClick={onClickHandler} >
                      <CgChevronRight />
                     </NextArrow>
                   )
               }
              showArrows={true}
              showStatus={false}
              showIndicators={false}
              showThumbs={false}
              infiniteLoop={true}
              transitionTime={"400"}
              interval={"4000"}
              autoPlay={true}
            >
              {props.slide.map((item, index) => {
                return(
                  <div key={index}>
                    <img
                      alt={index}
                      src={process.env.NODE_ENV !== "development"
                        ? item.slide.url
                        : process.env.REACT_APP_BACKEND_URL + item.slide.url}
                    />
                  </div>
                )
              })}
            </Carousel>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default Slider;
