import gql from "graphql-tag";

const SITE_QUERY = gql`
  query Site($id: ID!) {
    site(id: $id) {
      id
      title
      slug
      config
      phone
      email
      logo {
        url
        width
        height
      }
      favicon {
        url
      }
      ga_ua
      font_primary_woff {
        url
      }
      font_primary_woff2 {
        url
      }
      font_title_woff {
        url
      }
      font_title_woff2 {
        url
      }
      Footer {
        __typename
        ... on ComponentSiteFooter {
          baseline
          content
          address_line_1
          address_line_2
          address_zip
          address_city
          facebook_url
          twitter_url
          linkedin_url
          instagram_url
        }
      }
      popup {
        __typename
        Title
        title_text_align
        column_number
        zone_left
        zone_right
        button_left {
          text
          text_color
          link
          icon {
            url
          }
          background_color
          background_gradient
          border_radius
          border_size
          border_style
          border_color
          box_shadow
          border_color_hover
          background_color_hover
          text_color_hover
          is_opening_modal
        }
        button_right {
          text
          text_color
          link
          icon {
            url
          }
          background_color
          background_gradient
          border_radius
          border_size
          border_style
          border_color
          box_shadow
          border_color_hover
          background_color_hover
          text_color_hover
          is_opening_modal
        }
        separator_color
        desktop_width
        desktop_height
        mobile_width
        mobile_height
      }
      phone_button {
        text
        text_color
        link
        icon {
          url
        }
        background_color
        background_gradient
        border_radius
        border_size
        border_style
        border_color
        box_shadow
        border_color_hover
        background_color_hover
        text_color_hover
        padding
      }
    }
  }
`;

export default SITE_QUERY;
