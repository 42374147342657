import React from "react";
import styled from 'styled-components'

import ReactMarkdown from 'react-markdown'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const SubTitle = styled.div`
  padding: 32px 0 12px;

  color: ${props => props.color || "#fff"};
  font-size: 16px;
  font-weight: 800;
  line-height: 20px;
  text-transform: uppercase;
`;

const Title = styled.h2`
  padding-bottom: 16px;

  font-size: 32px;
  font-weight: 800;
  line-height: 42px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const HeroImage = styled.div`
  background-repeat: noe-repeat;
  background-size: cover;
  display: flex;

  height: 240px;
  max-width: 100%;

  @media screen and (min-width: 992px) {
    height: 340px;
  }
`;

const Split = ({ props }) => {

  return (
    <Container>
      <Row>

        {(props.direction === "left_image" && props.image) && (
          <Col col={12} sm={6}>
            <HeroImage
              style={{backgroundImage: `url(${process.env.NODE_ENV !== "development" ? props.image.url : process.env.REACT_APP_BACKEND_URL + props.image.url})`}}
            />
          </Col>
        )}
        <Col col={12} xs={12} sm={6} md={6} lg={6} xl={6} order={1} smOrder={0}>
          <div>
          {props.subtitle && (
            <SubTitle color="#B2C82C">{props.subtitle}</SubTitle>
          )}
          {props.title && (
            <Title>{props.title}</Title>
          )}
          {props.description && (
            <Description>
              <ReactMarkdown source={props.description} />
            </Description>
          )}
          </div>
        </Col>

        {(props.direction === "right_image" && props.image) && (
          <Col col={12} xs={12} sm={6} md={6} lg={6} xl={6} order={0} smOrder={1}>
            <HeroImage
              style={{backgroundImage: `url(${process.env.NODE_ENV !== "development" ? props.image.url : process.env.REACT_APP_BACKEND_URL + props.image.url})`}}
            />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Split;
