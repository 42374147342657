import React, { useEffect } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import ReactGA from 'react-ga';

import Query from "../../components/Query";
import PAGES_QUERY from "../../queries/pages";

import Site from "../Site";
import FourZeroFour from "../FourZeroFour";

function App() {

  // To remove Trailing slash
  const pathname = window.location.pathname;

  ReactGA.initialize({
    trackingId: process.env.REACT_APP_GA_UA_ID
  });

  useEffect( () => {
    // This line will trigger on a route change
    ReactGA.pageview(window.location.pathname + window.location.search);
  });

  return (
    <Query query={PAGES_QUERY} id={process.env.REACT_APP_SITE_ID}>
      {({ data: { pages } }) => {

        function rewriteRoute(slug) {
          let stg = slug;
          let path = slug;

          if (slug === "home" || slug === "/" || slug === null) {
            path = "/"
          }
          else {
            stg = "/" + slug + "/"
            path = stg.slice(0, -1)
          }
          return path
        }

        return (
          <Switch>
            <Redirect from="/:url*(/+)" to={pathname.slice(0, -1)} />
            {pages.map((page, index) => (
              <Route exact path={rewriteRoute(page.slug)} component={Site} key={index}/>
            ))}
            <Route component={FourZeroFour} />
          </Switch>
        )
      }}
      </Query>
  );
}

export default App;
