/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { Helmet } from "react-helmet"

const Seo = ({ creator, description, lang, meta, title, favicon }) => {

  const metaDescription = description

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: creator || "creator",
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
      link = {[
        { rel : 'canonical', href : process.env.REACT_APP_DOMAIN + window.location.pathname  }
      ]}
    >
      {favicon && (
        favicon.map((item, index) => (
          <link
            key={index}
            rel="icon"
            type="image/png"
            sizes={`${item.width}x${item.height}`}
            href={process.env.NODE_ENV !== "development" ? item.url : process.env.REACT_APP_BACKEND_URL + item.url}
          />
        )))}
      )}
      <script src="https://gadget.open-system.fr/jquery.min.js" type="text/JavaScript" ></script>
    </Helmet>
  )
}

Seo.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
}

Seo.propTypes = {
  description: PropTypes.string,
  lang: PropTypes.string,
  meta: PropTypes.arrayOf(PropTypes.object),
  title: PropTypes.string.isRequired,
  fontPrimaryUrl: PropTypes.string,
  fontTitleUrl: PropTypes.string
}

export default Seo
