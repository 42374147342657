import React from "react";
import styled from 'styled-components'

const Hr = styled.hr `
  border: 0;
  border-top: 1px solid ${props => props.color || "#fff"};
  position: relative;
  width: 80%;

  max-width: 436px;
`;

const Separator = ({ props }) => {

  return (
    <div>
      <Hr color={props.Color} />
    </div>
  );
};

export default Separator;
