import React from "react";
import styled from 'styled-components';
import { Link } from "react-router-dom";

import { Container, Row, Col } from 'styled-bootstrap-grid';
import Nav from "./nav";

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 1rem 0;

  @media (min-width: 768px) {
    margin: 2rem 0;
  }
`;

const Logo = styled(Link)`
  display: inline-block;

  img {
    max-height: 32px;
    width: auto;

    @media (min-width: 768px) {
      height: ${ props => props.config.height || "82px" };
      max-height: none;
      width: ${ props => props.config.width || "auto" };
    }
  }
`;

const List = styled.div`

  ul {

    vertical-align: middle;

    @media (min-width: 992px) {
      /* margin-top: 34px; */
    }
  }
`;

const Header = ({ props }) => {

  const imageUrl =
    process.env.NODE_ENV !== "development"
      ? props.logo
      : process.env.REACT_APP_BACKEND_URL + props.logo;

  return (
    <Container fluid>
      <Row>
        <Col col xl="12" lg="12" md="12" sm="12" xs="12">
          <Container>
            <Row>
              <Col col xl="12" lg="12" md="12" sm="12" xs="12">
                <Wrapper>
                  <Logo to="/" config={props.config.logo}>
                    <img src={imageUrl} alt="" />
                  </Logo>
                  <List>
                    <Nav siteSettings={props}/>
                  </List>

                </Wrapper>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};

export default Header;
