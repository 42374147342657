import gql from "graphql-tag";

const NAV_QUERY = gql`
  query Nav($id: ID!) {
    pages(where: {
      site: {
        id: $id
      }
    }) {
      order
      id
      title
      slug
      is_in_nav
      is_in_footer
    }
  }
`;

export default NAV_QUERY;
