import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

import Button from "./button";

const H1 = styled.h1 `
  padding-bottom: 1rem;

  font-size: 2rem;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 3rem;
  }
`;

const SubTitle = styled.div `
  margin-bottom: 2rem;

  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
`;

const Action = styled.div `
  text-align: center;
`;

const HeroImageWrapper = styled.div`
  text-align: center;
`;

const HeroImage = styled.img`
  height: ${props => props.imageHeight || "auto"};
  margin-top: 2rem;
  max-width: 100%;
  width: ${props => props.imageWidth || "100%"};
`;

const Hero = ( props ) => {

  return (
    <Container>
      <Row>
        <Col col xl="12" lg="12" md="12" sm="12" xs="12">
          {props.bloc.title && (
            <H1>{props.bloc.title}</H1>
          )}

          {props.bloc.subtitle && (
            <SubTitle>{props.bloc.subtitle}</SubTitle>
          )}

          {(props.bloc.button_primary || props.bloc.button_secondary) && (
            <Action>
              {props.bloc.button_primary && (
                <Button content={props.bloc.button_primary} popup={props.site.popup} />
              )}
              {props.bloc.button_secondary && (
                <Button content={props.bloc.button_secondary} popup={props.site.popup} />
              )}
            </Action>
          )}
        </Col>

        {props.bloc.image && (
          <Col col xl="12">
            <HeroImageWrapper>
              <HeroImage
                alt={"hero_image"}
                src={process.env.NODE_ENV !== "development" ? props.bloc.image.url : process.env.REACT_APP_BACKEND_URL + props.bloc.image.url}
                imageHeight={props.bloc.image_height}
                imageWidth={props.bloc.image_width}
              />
            </HeroImageWrapper>
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default Hero;
