import React from "react";

import { createGlobalStyle } from 'styled-components';

// import HkGrotestRegularWoff from './fonts/hkgrotesk-regular-webfont.woff';
// import HkGrotestRegularWoff2 from './fonts/hkgrotesk-regular-webfont.woff2';

// import HkGrotestExtraBoldWoff from './fonts/hkgrotesk-extrabold-webfont.woff';
// import HkGrotestExtraBoldWoff2 from './fonts/hkgrotesk-extrabold-webfont.woff2';

const Style = createGlobalStyle`

  body {
    background-color: #fff;

    font-family: ${ props => props.fontPrimary || 'Arial'};
  }

  p {
    line-height: 20px;
    margin-bottom: 20px;
  }

  h1, h2, h3, h4 {
    font-family: ${ props => props.fontTitle || 'Arial'};
    font-weight: ${ props => props.fontTitleWeight || '800'};
  }

  h1 {
    padding-bottom: 2.5rem;

    font-size: 2.5rem;
  }

  h2 {
    padding-bottom: 2rem;

    font-size: 2rem;
  }

  h3 {
    padding-bottom: 1.5rem;

    font-size: 1.5rem;
  }

  h4 {
    padding-bottom: 1.25rem;

    font-size: 1.25rem;
  }

  h5 {
    padding-bottom: 1rem;

    font-size: 1rem;
  }

  strong {
    font-weight: bold;
  }

  em {
    font-style: italic;
  }

  .ReactModal__Body--open {
    overflow: hidden;
  }
`;

const MyGlobalStyle = ({ config }) => {

    return (
      <Style
        fontPrimary={config.config.font.font_primary}
        fontPrimaryWeight={config.config.font.font_primary_weight}
        fontPrimaryUrl={config.config.font.font_primary_url}
        fontPrimaryWoff={config.config.font.font_primary_woff}
        fontPrimaryWoff2={config.config.font.font_primary_woff2}
        fontTitle={config.config.font.font_title}
        fontTitleWeight={config.config.font.font_title_weight}
        fontTitleUrl={config.config.font.font_title_url}
        fontTitleWoff={config.config.font.font_title_woff}
        fontTitleWoff2={config.config.font.font_title_woff2}
      />
    )

}

export default MyGlobalStyle;
