import React from "react";
import { useMediaQuery } from 'react-responsive'

import { Container, Row, Col } from 'styled-bootstrap-grid';

import styled from 'styled-components'

const Image = styled.img`
  height: auto;
  margin: 0 auto;
  max-width: 100%;
  width: auto;
`;

const ResponsiveImages = ({ props }) => {

  const isDesktop = useMediaQuery({ query: '(min-width: 1200px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px) and (max-width: 1199px)' });
  const isMobile = useMediaQuery({ query: '(min-width: 320px) and (max-width: 767px)' });

  const imageUrlDesktop = process.env.NODE_ENV !== "development" ? props.image_desktop.url : process.env.REACT_APP_BACKEND_URL + props.image_desktop.url;
  const imageUrlTablet = process.env.NODE_ENV !== "development" ? props.image_tablet.url : process.env.REACT_APP_BACKEND_URL + props.image_tablet.url;
  const imageUrlMobile = process.env.NODE_ENV !== "development" ? props.image_mobile.url : process.env.REACT_APP_BACKEND_URL + props.image_mobile.url;

  const ImgWrapperRender = function(props) {

    return (
      <>
        {props.props.link ? (
          <div>
            <a href={props.props.link} target="_blank" rel="noopener noreferrer">{props.children}</a>
          </div>
        ):(
          <div>{props.children}</div>
        )}
      </>
    )
  }

  return (
    <Container>
      <Row>
        <Col col={12}>
          <ImgWrapperRender props={props} style={{ marginBottom: "-3px" }}>
            {props.image_desktop.url && isDesktop && (
              <Image
                src={imageUrlDesktop}
                alt="Image"
              />
            )}

            {props.image_tablet.url && isTablet && (
              <Image
                src={imageUrlTablet}
                alt="Image"
              />

            )}

            {props.image_mobile.url && isMobile && (
              <Image
                src={imageUrlMobile}
                alt="Image"
              />

            )}
          </ImgWrapperRender>
        </Col>
      </Row>
    </Container>
  );
};

export default ResponsiveImages;
