import React from "react";
import styled from 'styled-components';

import Modal from 'react-modal';
import { CgCloseO } from 'react-icons/cg';


const AnchorButton = styled.a`
  background-color: ${props => props.config.background_color || "#000"};
  background-image: ${props => props.config.background_gradient || "none"};
  box-shadow: ${props => props.config.box_shadow || "none"};
  border: ${props => props.config.border_size + " " + props.config.border_style + " " + props.config.border_color || "1px solid #000"};
  border-radius: ${props => props.config.border_radius || "0"};
  display: inline-block;
  margin: 0.5rem;
  outline: none;
  padding: ${props => props.config.padding || "0.75rem 1.5rem"};

  color: ${props => props.config.text_color || "#fff"};
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.25rem;
  text-align: center;
  vertical-align: middle;

  transition: all .2s ease;

  &:hover, &:focus {
    background-color: ${props => props.config.background_color_hover || props.config.background_color || "inherit"};
    border-color: ${props => props.config.border_color_hover || "inherit"};
    outline: none;

    color: ${props => props.config.text_color_hover || props.config.text_color || "inherit"};
  }

  &.pt-2 {
    margin-top: 2rem;
  }

  i {
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    height: 16px;
    margin-right: 0.5rem;
    margin-top: 1px;
    width: 16px;
  }
`;

const StyledButton = styled.button`
  background-color: ${props => props.config.background_color || "#000"};
  background-image: ${props => props.config.background_gradient || "none"};
  box-shadow: ${props => props.config.box_shadow || "none"};
  border: ${props => props.config.border_size + " " + props.config.border_style + " " + props.config.border_color || "1px solid #000"};
  border-radius: ${props => props.config.border_radius || "0"};
  display: inline-block;
  margin: 0.5rem;
  outline: none;
  padding: 0.75rem 1.5rem;
  -webkit-appearance: none;

  color: ${props => props.config.text_color || "#fff"};
  cursor: pointer;
  font-size: 1rem;
  font-weight: 800;
  line-height: 1.25rem;
  text-align: center;
  vertical-align: middle;

  transition: all .2s ease;

  &:hover, &:focus {
    background-color: ${props => props.config.background_color_hover || props.config.background_color || "inherit"};
    border-color: ${props => props.config.border_color_hover || "inherit"};
    outline: none;

    color: ${props => props.config.text_color_hover || props.config.text_color || "inherit"};
  }
`;

const Inner = styled.div`
  padding: 3rem 2rem 3rem;
  position: relative;
  width: calc( 100vw - 2rem );

  @media screen and (min-width: 768px) {
    width: 704px;
  }

  @media screen and (min-width: 992px) {
    width: 800px;
  }
`;

const Close = styled.button`
  background: transparent;
  border: none;
  margin: 0;
  outline: none;
  overflow: visible;
  padding: 0;
  position: absolute;

  right: 1rem;
  top: 1rem;

  color: inherit;
  cursor: pointer;
  font-size: 1.5rem;
  line-height: normal;
  -webkit-font-smoothing: inherit;
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
`;

const Title = styled.h3`
  margin: 0 0 1.5rem;

  text-align: center;
`;

const Content = styled.div`
  display: block;
  margin: -1rem;

  @media screen and (min-width: 768px) {
    display: flex;
  }


  & > div {
    flex: none;
    padding: 1rem;

    text-align: center;

    &:nth-child(2) {
      border-top: 1px solid ${props => props.borderColor || "#f5f5f6"};
      margin-top: 1rem;
      padding-top: 2rem;
    }

    @media screen and (min-width: 768px) {
      flex: 1 1 0;

      &:nth-child(2) {
        border-left: 1px solid ${props => props.borderColor || "#f5f5f6"};
        border-top: 0;
        margin-top: 0;
        padding-top: 1rem;
      }
    }


  }
`;

const Button = ( props ) => {

  const TitleRender = function({props}) {

    return (
      <>
        {props.title_text_align === "left" && (
          <Title style={{'textAlign': 'left','marginTop': '-1rem'}} dangerouslySetInnerHTML={{__html: props.Title}} />
        )}
        {props.title_text_align === "center" && (
          <Title style={{'textAlign': 'center','marginTop': '-1rem'}} dangerouslySetInnerHTML={{__html: props.Title}} />
        )}
        {props.title_text_align === "right" && (
          <Title style={{'textAlign': 'right','marginTop': '-1rem'}} dangerouslySetInnerHTML={{__html: props.Title}} />
        )}
      </>
    )
  }

  Modal.setAppElement('#root');

  const customStyles = {
    overlay: {
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(34, 34, 34, 0.5)'
    },
    content : {
      top                   : '50%',
      left                  : '50%',
      right                 : 'auto',
      bottom                : 'auto',
      transform             : 'translate(-50%, -50%)',
      border                : '0',
      padding               : '0',
      maxHeight             : '96vh',
      overflow              : 'auto'
    }
  };

  const [modalIsOpen,setIsOpen] = React.useState(false);

  function openModal() {
    setIsOpen(true);
  }

  function closeModal(){
    setIsOpen(false);
  }

  return (
    <>
      {props.content.link ? (
        <AnchorButton href={props.content.link || "#"} config={props.content}>
          {props.content.icon && (
            <i className="icon" style={{ backgroundImage: `url(${process.env.NODE_ENV !== "development"
              ? props.content.icon.url
              : process.env.REACT_APP_BACKEND_URL + props.content.icon.url})`}}
            />
          )}
          {props.content.text}
        </AnchorButton>
      ):((props.content.is_opening_modal && props.popup) && (
        <>
          <StyledButton
            config={props.content}
            onClick={openModal}
            type="button"
          >
          {props.content.text}
          </StyledButton>

          <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            style={customStyles}
            contentLabel="Example Modal"
          >
            <Inner>
              <Close onClick={closeModal}>
                <CgCloseO />
              </Close>
              {props.popup.Title && (
                <TitleRender props={props.popup} />
              )}
              <Content
                borderColor={props.popup.separator_color}
              >
                {props.popup.zone_left && (
                  <div>
                    <div dangerouslySetInnerHTML={{__html: props.popup.zone_left}} />
                    {props.popup.button_left && (
                      <AnchorButton className="pt-2" href={props.popup.button_left.link || "#"} config={props.popup.button_left}>{props.popup.button_left.text}</AnchorButton>
                    )}
                  </div>
                )}

                {props.popup.zone_right && (
                  <div>
                    <div dangerouslySetInnerHTML={{__html: props.popup.zone_right}} />
                    {props.popup.button_right && (
                      <AnchorButton className="pt-2" href={props.popup.button_right.link || "#"} config={props.popup.button_right}>{props.popup.button_right.text}</AnchorButton>
                    )}
                  </div>
                )}
              </Content>
            </Inner>
          </Modal>
        </>
      ))}
    </>
  )

};

export default Button;
