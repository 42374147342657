import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const Tile = styled.div`
  margin-bottom: 32px;
  padding: 16px 0;

  text-align: center;
`;

const IconImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  height: ${ props => props.iconHeight ? (props.iconHeight+"px"):("64px") };
  margin: 0 auto 16px;
  width: ${ props => props.iconWidth ? (props.iconWidth+"px"):("64px") };
`;

const Title = styled.div`
  margin-bottom: 16px;

  font-size: 24px;
  font-weight: 800;
  line-height: 30px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 20px;
`;

const Grid = ({ props }) => {

  return (
    <Container>
      <Row justifyContent="center">
        {props.Tile.map((tile, index) =>  (
          <Col col lg="4" md="4" sm="12" xs="12" key={index}>
            <Tile>
              {tile.icon && (
                <IconImage
                  iconHeight={tile.icon_height}
                  iconWidth={tile.icon_width}
                  style={{backgroundImage: `url(${process.env.NODE_ENV !== "development"
                    ? tile.icon.url
                    : process.env.REACT_APP_BACKEND_URL + tile.icon.url})`}}
                />
              )}
              {tile.title && (
                <Title dangerouslySetInnerHTML={{__html: tile.title}} />
              )}
              {tile.description && (
                <Description dangerouslySetInnerHTML={{__html: tile.description}} />
              )}
            </Tile>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default Grid;
