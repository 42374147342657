import React from "react";
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieConsent = ({ config }) => {

  return (
    <CookieBanner
      message={"Ce site utilise des cookies nécessaires à son bon fonctionnement qui ne peuvent pas être désactivés. En les acceptants vous autorisez des services tiers, vous acceptez le dépôt et la lecture de cookies et l'utilisation de technologies de suivi nécessaires à leur bon fonctionnement."}
      wholeDomain={true}
      onAccept = {() => {}}
      onAcceptPreferences = {() => {}}
      onAcceptStatistics = {() => {}}
      onAcceptMarketing = {() => {}}
      showNecessaryOption={false}
      showMarketingOption={false}
      showStatisticsOption={false}
      showPreferencesOption={false}
      necessaryOptionText={"Cookies obligatoires"}
      policyLink={"/privacy"}
      acceptButtonText={"Accepter"}
      privacyPolicyLinkText={"Politique de confidentialité"}
      styles={{
        dialog: {
          backgroundColor: "rgba(23,23,23,0.64)",
          position: "fixed",
          bottom: "0",
          top: "0",
          left: 0,
          right: 0,
          zIndex: "1",
        },
        container: {
          backgroundColor: "#fff",
          position: "fixed",
          bottom: "0",
          padding: "2rem",
          zIndex: "2"
        },
        message: {
          color: '#232323',
        },
        policy: {
          color: "#232323",
          textDecoration: "underline",
          margin: "0",
        },
        optionWrapper: {
          display: 'none',
        },
        button: {
          border: config.button_primary.border || "0",
          backgroundColor: config.button_primary.background_color || "#232323",
          borderRadius: config.button_primary.border_radius || "0",
          display: "inline-block",
          outline: "none",
          padding: config.button_primary.padding || "0.75rem 1.5rem",
          color: config.button_primary.color || "#fff",
          fontSize: "1rem",
          fontWeight: "bold",
          lineHeight: "1.25rem",
          textAlign: "center",
          verticalAlign: "middle",
          cursor: "pointer"
        }
      }}
    />
  );
};

export default CookieConsent;
