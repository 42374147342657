import React from "react";
import { Reset } from 'styled-reset';
import styled from 'styled-components';
// import ReactGA from 'react-ga';

import MyGlobalStyle from "../../assets/global-style";
import { BaseCSS } from 'styled-bootstrap-grid';

import Query from "../../components/Query";
import Seo from "../../components/seo";

import Header from "../../components/header";
import Footer from "../../components/footer";
import PageBuilder from "../PageBuilder";

import CookieConsent from "../../components/cookie-banner";

import SITE_QUERY from "../../queries/site";
import PAGES_QUERY from "../../queries/pages";

const Main = styled.main`
  background-color: ${props => props.bgColor || "#fff"};
  min-height: 100vh;

  font-family: ${props => props.fontPrimary || "Arial"};
  font-weight: ${props => props.fontPrimaryWeight || "400"};

  color: ${props => props.textColor || "#000"};
`;

const Wrapper = styled.div`
  min-height: calc( 100vh - 144px );
`;

const Site = ( props ) => {

  // cette classe permet de :
  // recherche dans l'ensemble des pages du site laquelle il faut charger
  // passer à tout child-component les infos du site
  // appeler le PageBuilder pour y insérer le contenu dynamiquement

  return (
    <Query query={SITE_QUERY} id={process.env.REACT_APP_SITE_ID}>
      {({ data: { site } }) => {

        const siteSettings = {
          id: site.id,
          title: site.title,
          config: site.config,
          logo: site.logo.url,
          email: site.email,
          phone: site.phone,
          phone_button: site.phone_button,
          ga_ua: site.ga_ua,
          popup: site.popup
        }

        return (

    <Query query={PAGES_QUERY} id={process.env.REACT_APP_SITE_ID}>
      {({ data: { pages } }) => {
        const pathname = props.location.pathname;

        let currentRoute;
        if (pathname === "/" || "" || null) {
          currentRoute = "home";
        }
        else {
          currentRoute = pathname.substring(1);
        }

        // ensuite, on recherche dans le tableau avec .filter()
        const findPage = pages.filter(page => {
          return page.slug === currentRoute;
        })

        const page = findPage[0];

        return (
          <Main
            bgColor={site.config.background_color}
            textColor={site.config.text_color}
            fontPrimary={site.config.font.font_primary}
            fontPrimaryWeight={site.config.font.font_primary_weight}
          >
            <Reset />
            <BaseCSS />
            {!site.font_primary_woff && !site.font_primary_woff2 && (
              <>
              {site.config.font.font_title_url && (
                <style dangerouslySetInnerHTML={{ __html:
                  `
                    @import url(${site.config.font.font_title_url});
                  `
                }} />
              )}
              {site.config.font.font_primary_url && (
                <style dangerouslySetInnerHTML={{ __html:
                  `
                    @import url(${site.config.font.font_primary_url});
                  `
                }} />
              )}
              </>
            )}


            {site.font_primary_woff && site.font_primary_woff2 && (
              <style dangerouslySetInnerHTML={{ __html:
                `
                  @font-face {
                    font-family: 'HK Grotesk';
                    font-weight: 400;
                    src: url(${site.font_primary_woff2.url}) format('woff2'),
                         url(${site.font_primary_woff.url}) format('woff');
                  }
                `
              }} />
            )}

            {site.font_title_woff && site.font_title_woff2 && (
              <style dangerouslySetInnerHTML={{ __html:
                `
                  @font-face {
                    font-family: 'HK Grotesk';
                    font-weight: 800;
                    src: url(${site.font_title_woff2.url}) format('woff2'),
                         url(${site.font_title_woff.url}) format('woff');
                  }
                `
              }} />
            )}

            <MyGlobalStyle
              config={site}
            />
            <Seo
              title={page.seo_title}
              description={page.seo_description}
              favicon={site.favicon}
            />
            <Wrapper>
              <Header props={siteSettings} />
              <PageBuilder page={page} site={site} />
            </Wrapper>
            <Footer props={site} />
            <CookieConsent config={site.config} />
          </Main>
        );
    }}
    </Query>

  )
}}
</Query>
  );
}

export default Site;

// // Google Analytics
// export const initGA = () => {
//   ReactGA.initialize(process.env.REACT_APP_GA_UA_ID);
//   ReactGA.pageview(window.location.pathname + window.location.search);
// }
