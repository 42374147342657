import gql from "graphql-tag";
// import GET_HIGHLIGHTED_CONTENT from "./fragments/getHighlitedContent";

const PAGES_QUERY = gql`
query getAllPagesAndContent($id: ID!) {
  pages(where : {
    site: {
      id: $id
    }
  }) {
    id
    title
    slug
    seo_title
    seo_description
    Constructor {
      __typename
    }
  }
}
`;

export default PAGES_QUERY;
