import React from "react";

import SEO from "../../components/seo";

const FourZeroFour = () => {
  return (
    <>
      <SEO
        title="404 - Not found"
        description="Donec id elit non mi porta gravida at eget metus. Nullam quis risus eget urna mollis ornare vel eu leo. Duis mollis, est non commodo luctus, nisi erat porttitor ligula, eget lacinia odio sem nec elit."
      />
      <div>
        <h1>404 - Not found</h1>
      </div>
    </>
  );
}

export default FourZeroFour;
