const gridConfig = {
  gridColumns: 12,
  breakpoints: {
    xxl: 1440,
    xl: 1200,
    lg: 992,
    md: 768,
    sm: 576,
    xs: 575
  },
  row: {
    padding: 0, // default 15
  },
  col: {
    padding: 16, // default 15
  },
  container: {
    padding: 0,
    maxWidth: {
      xxl: 1080,
      xl: 1080,
      lg: 960,
      md: 720,
      sm: 540,
      xs: 540,
    }
  },
};

export default gridConfig;
