import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';

const Item = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 2rem;
  padding: 16px 0;

  text-align: left;
`;

const IconImage = styled.div`
  background-size: contain;
  background-repeat: no-repeat;
  height: ${ props => props.iconHeight ? (props.iconHeight+"px"):("64px") };
  margin-right: 2rem;
  width: ${ props => props.iconWidth ? (props.iconWidth+"px"):("64px") };
`;

const Description = styled.div`
  font-size: 1.25rem;
  font-weight: 800;
  line-height: 1.5rem;

  @media screen and (min-width: 768px) {
    font-size: 1.5rem;
    line-height: 2rem;
  }
`;

const List = ({ props }) => {

  return (
    <Container>
      <Row justifyContent="center">
        {props.items.map((item, index) =>  (
          <Col col lg="6" md="6" sm="12" xs="12" key={index}>
            <Item>
              {item.icon && (
                <IconImage
                  iconHeight={item.icon_height}
                  iconWidth={item.icon_width}
                  style={{backgroundImage: `url(${process.env.NODE_ENV !== "development"
                    ? item.icon.url
                    : process.env.REACT_APP_BACKEND_URL + item.icon.url})`}}
                />
              )}
              {item.description && (
                <Description>{item.description}</Description>
              )}
            </Item>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default List;
