import React from "react";
import { hydrate, render } from "react-dom";
import { ApolloProvider } from "react-apollo";
import App from "./containers/App";
import client from "./utils/apolloClient";
import { BrowserRouter as Router } from "react-router-dom";
import "./index.css";

import { GridThemeProvider } from 'styled-bootstrap-grid';
import { ThemeProvider } from 'styled-components';

// import config file(s)
import gridConfig from "./utils/grid-config";

const rootElement = document.getElementById("root");

const styledTheme = {
  mainColor: 'purple',
}

if (rootElement.hasChildNodes()) {
  hydrate(
    <Router>
      <ApolloProvider client={client}>
        <ThemeProvider theme={styledTheme}>
          <GridThemeProvider gridTheme={gridConfig}>
            <App />
          </GridThemeProvider>
        </ThemeProvider>
      </ApolloProvider>
    </Router>,
    rootElement
  );
} else {
  render(
    <Router>
      <ApolloProvider client={client}>
      <ThemeProvider theme={styledTheme}>
        <GridThemeProvider gridTheme={gridConfig}>
          <App />
        </GridThemeProvider>
      </ThemeProvider>
      </ApolloProvider>
    </Router>,
    rootElement
  );
}
