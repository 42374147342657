import React, { useState } from "react";
import { NavLink } from "react-router-dom";

import styled from 'styled-components'

import Query from "./Query";
import NAV_QUERY from "../queries/nav";

import Button from "./button";


const ListItem = styled.li`
  display: inline-block;
  margin-left: 32px;

  font-weight: 800;

  &.hide-mobile {
    @media (max-width:767px) {
      display: none;
    }
  }
`;

const LinkItem = styled.span`
  display: none;

  @media screen and (min-width: 992px) {
    border-bottom: ${props => props.borderBottom || "0"};
    display: block;
    text-transform: ${props => props.textTransforn || "none"};
    transition: .25s all;

    color: ${props => props.color || "#fff"};

    &:hover, &:focus {
      border-bottom: ${props => props.borderBottomHover || "0"};
      color: ${props => props.colorHover || "#fff"};
    }
  }
`;

const ButtonWrapper = styled.div`
  display: none;

  @media (min-width: 768px) {
    display: block;
  }
`;

const ToggleNav = styled.div`
  appearance: none;
  border: 0;
  background: transparent;
  color: ${props => props.color || "#fff"};
  padding: 0 8px;
  outline: none;

  cursor: pointer;
  font-size: 32px;
  line-height: 50px;
  vertical-align: middle;

  transition: all 0.2s ease;


  &:hover {
    color: orange;
  }

  &[aria-expanded="true"] {

  }

  @media screen and (min-width: 992px) {
    display: none;
  }
`;

const MobileNav = styled.div`
  background: #fff;
  display: block;
  left: 0;
  margin-left: -1rem;
  margin-right: -1rem;
  overflow: hidden;
  position: absolute;
  right: 0;
  top: 67px;
  z-index: 1;

  max-height: 0;
  transition: max-height .8s ease-in-out;

  @media (min-width: 768px) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
    top: 149px;
  }

  @media (min-width: 992px) {
    display: none;
  }

  &.opened {
    max-height: 100vh;
  }
`;

const MobileNavList = styled.div`
  list-style: none;
`;

const MobileNavLink = styled.li`
  border-top: 1px solid #f5f5f6;
  padding: 16px 0;
  text-align: center;

  /* color: #141438; */
  font-size: 16px;
  font-weight: 800;
  line-height: 24px;

  &:first-child {
    border-top: 0;
  }
`;

const NavBurger = styled.div`
  width: 26px;
  height: 20px;
  position: relative;
  top: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 8px;
  }

  span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: ${props => props.bgColor ||"#fff"};
    border-radius: 9px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
  }

  span:nth-child(1) {
    top: 0px;
  }

  span:nth-child(2) {
    top: 8px;
  }

  span:nth-child(3) {
    top: 16px;
  }

  &[aria-expanded="true"] span:nth-child(1) {
    top: 8px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
  }

  &[aria-expanded="true"] span:nth-child(2) {
    opacity: 0;
    left: -60px;
  }

  &[aria-expanded="true"] span:nth-child(3) {
    top: 8px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
  }
`;

const Nav = ( props ) => {

  // MOBILE NAVBAR
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);
  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  // Page sorting by order parameter
  function sortPages(a, b) {
    const pageA = a.order;
    const pageB = b.order;

    let comparison = 0;
    if (pageA > pageB) {
      comparison = 1;
    } else if (pageA < pageB) {
      comparison = -1;
    }
    return comparison;
  }

  return (
    <div>
      <Query query={NAV_QUERY} id={process.env.REACT_APP_SITE_ID}>
        {({ data: { pages } }) => {

          // Filter array with is_in_nav boolean
          var navPagesToDisplay = pages.filter(function (page) {
            return page.is_in_nav === true;
          });

          // Sort nav pages
          const sortedPages = navPagesToDisplay.sort(sortPages);

          return (
            <nav>
              <ul id="myTopnav">
                {sortedPages.map((page, i) => {
                  return (
                    <ListItem className="hide-mobile" key={page.id}>
                      <NavLink
                        exact
                        to={`/${page.slug === "home" ? "" : page.slug}`}
                      >
                        <LinkItem
                          borderBottom={props.siteSettings.config.nav.border_bottom}
                          borderBottomHover={props.siteSettings.config.nav.border_bottom_hover}
                          color={props.siteSettings.config.nav.color}
                          colorHover={props.siteSettings.config.nav.color_hover}
                          textTransforn={props.siteSettings.config.nav.text_transform}
                        >{page.title}</LinkItem>
                      </NavLink>
                    </ListItem>
                  )
                })}

                {props.siteSettings.phone && props.siteSettings.phone_button && (
                  <ListItem className="hide-mobile">
                    <ButtonWrapper>
                      <Button content={props.siteSettings.phone_button} popup={props.siteSettings.popup} />
                    </ButtonWrapper>
                  </ListItem>
                )}

                <ListItem>
                  <ToggleNav
                    type="button"
                    className="icon"
                    color={props.siteSettings.config.text_color}
                    data-toggle="collapse"
                    data-target="#navbarsExample09"
                    aria-controls="navbarsExample09"
                    aria-expanded={!isNavCollapsed ? true : false}
                    aria-label="Toggle navigation"
                    onClick={handleNavCollapse}
                  >
                    <NavBurger
                      aria-expanded={!isNavCollapsed ? true : false}
                      id="nav-icon1"
                      bgColor={props.siteSettings.config.text_color}
                    >
                      <span></span>
                      <span></span>
                      <span></span>
                    </NavBurger>

                  </ToggleNav>
                </ListItem>
              </ul>

              <MobileNav
                className={`${isNavCollapsed ? '' : 'opened'}`}
                id="navbarsExample09"
                style={{ backgroundColor: props.siteSettings.config.background_color }}
              >
                <MobileNavList>
                {sortedPages.map((page, i) => {
                  return (
                    <MobileNavLink key={i} >
                      <NavLink
                        exact
                        to={`/${page.slug === "home" ? "" : page.slug}`}
                        key={page.id}
                        style={{ 'color': props.siteSettings.config.text_color }}
                      >
                        {page.title}
                      </NavLink>
                    </MobileNavLink>
                  )
                })}
                </MobileNavList>
              </MobileNav>

            </nav>
          );
        }}
      </Query>
    </div>
  );
};

export default Nav;
