import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';


const Column = styled.div`
  margin-bottom: 8rem;

  text-align: center;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  margin-bottom: 1.5rem;
`;

const ListItem = styled.li`
  background-color: ${ props => props.bgColor || "#fff" };
  margin-bottom: 1px;
  min-height: 2.75rem;

  color: ${ props => props.txtColor || "#000" };
  font-size: 0.875rem;
  line-height: 2.5rem;

  span {
    display: inline-block;

    line-height: 1rem;
    vertical-align: middle;
  }
`;

const Table = ({ props }) => {

  return (
    <>
    {props.columns && (
      <Container>
        <Row justifyContent={"center"}>
          {props.columns.map((column, index) => (
            <Col col={12} md={3} key={index}>
              <Column>
                {column.blocs.map((bloc, index) =>  (
                  <List key={index} separatorColor={column.blocs_separator_color}>
                    {bloc.lines.map((line, index) =>  (
                      <ListItem
                        key={index}
                        bgColor={line.background_color}
                        txtColor={line.text_color}
                        dangerouslySetInnerHTML={{ __html: line.feature }}
                      />
                    ))}
                  </List>
                ))}
              </Column>
            </Col>
          ))}
        </Row>
      </Container>
    )}
    </>
  );
};

export default Table;
