import React from "react";
import ReactMarkdown from 'react-markdown'

import { Container, Row, Col } from 'styled-bootstrap-grid';

import styled from 'styled-components'

const Format = styled.div`
  color: ${ props => props.color || "inherit"};
  text-align: ${ props => props.textAlign || "left" };

  a {
    color: ${ props => props.color || "inherit"};
    text-decoration: underline;
  }
`;

const Text = ({ props }) => {

  return (
    <Container>
      <Row>
        <Col col={12}>
          <Format textAlign={props.text_align} color={props.text_color}>
            <ReactMarkdown source={props.content} />
          </Format>
        </Col>
      </Row>
    </Container>
  );
};

export default Text;
