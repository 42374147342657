import React from "react";
import styled from 'styled-components'
import { Container, Row, Col } from 'styled-bootstrap-grid';

import Button from "./button";

const Wrapper = styled.div`
  background-color: ${props => props.bgColor || "#fff"};
  padding: 48px;
  position: relative;
  overflow: hidden;
`;

const Pattern = styled.div`

  &::after {
    content: "";
    background-image: ${props => `url(${process.env.REACT_APP_BACKEND_URL + props.bloc.pattern})` || "none"};
    position: absolute;
    top: -50px;
    bottom: -50px;
    left: -50px;
    right: -50px;

    transform: rotate(12deg);

    opacity: 0.08;
    z-index: 0;
  }
`;

const Inner = styled.div`
  text-align: center;
  position: relative;
  z-index: 1;

  @media screen and (min-width: 768px) {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }
`;

const Title = styled.div`
  font-size: 1.5rem;
  font-weight: bold;
`;

const ButtonWrapper = styled.div`
  margin-top: 24px;

  @media screen and (min-width: 768px) {
    margin-top: 0;
  }
`;

const Highlighted = ( props ) => {

  return (
    <Container>
      <Row>
        <Col col={12}>
          <Wrapper bgColor={props.bloc.bgColor}>
            {props.bloc.pattern && (
              <Pattern pattern={process.env.NODE_ENV !== "development" ? props.bloc.pattern.url : process.env.REACT_APP_BACKEND_URL + props.bloc.pattern.url} />
            )}
            <Inner>
              <Title>Ceci est le bloc Highlighted</Title>
              {props.bloc.button && (
                <ButtonWrapper>
                  <Button content={props.bloc.button} />
                </ButtonWrapper>
              )}
            </Inner>
          </Wrapper>
        </Col>
      </Row>
    </Container>
  );
};

export default Highlighted;
