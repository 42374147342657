import React from "react";
import styled from 'styled-components';

import Button from "./button";

import { Container, Row, Col } from 'styled-bootstrap-grid';

const H1 = styled.h1`
  padding-bottom: 0;

  text-align: center;
`;

const H2 = styled.h2`
  padding-bottom: 0;

  text-align: center;
`;

const H3 = styled.h3`
  padding-bottom: 0;

  text-align: center;
`;

const H4 = styled.h4`
  padding-bottom: 0;

  text-align: center;
`;

const H5 = styled.h5`
  padding-bottom: 0;

  text-align: center;
`;

const Div = styled.div`
  padding-bottom: 0;

  text-align: center;
`;


const SubTitle = styled.div `
  padding-top: 1.5rem;

  font-size: 1rem;
  line-height: 1.25rem;
  text-align: center;

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
    line-height: 1.625rem;
  }
`;

const Action = styled.div `
  margin-top: 2rem;

  text-align: center;
`;

const Title = ( props ) => {

  const TagRender = function(props) {

    if (props.bloc === "title") {
      switch(props.tag) {
        case 'h1':
          return (<H1 dangerouslySetInnerHTML={{__html: props.content}} />);
        case 'h2':
          return (<H2 dangerouslySetInnerHTML={{__html: props.content}} />);
        case 'h3':
          return (<H3 dangerouslySetInnerHTML={{__html: props.content}} />);
        case 'h4':
          return (<H4 dangerouslySetInnerHTML={{__html: props.content}} />);
        case 'h5':
          return (<H5 dangerouslySetInnerHTML={{__html: props.content}} />);
        case 'div':
          return (<Div dangerouslySetInnerHTML={{__html: props.content}} />);
        default:
          return (<H2 dangerouslySetInnerHTML={{__html: props.content}} />);
      }
    }

    if (props.bloc === "subtitle") {
      switch(props.tag) {
        case 'h1':
          return (<H1><SubTitle dangerouslySetInnerHTML={{__html: props.content}} /></H1>);
        case 'h2':
          return (<H2><SubTitle dangerouslySetInnerHTML={{__html: props.content}} /></H2>);
        case 'h3':
          return (<H3><SubTitle dangerouslySetInnerHTML={{__html: props.content}} /></H3>);
        case 'h4':
          return (<H4><SubTitle dangerouslySetInnerHTML={{__html: props.content}} /></H4>);
        case 'h5':
          return (<H5><SubTitle dangerouslySetInnerHTML={{__html: props.content}} /></H5>);
        case 'div':
          return (<SubTitle dangerouslySetInnerHTML={{__html: props.content}} />);
        default:
          return (<SubTitle dangerouslySetInnerHTML={{__html: props.content}} />);
      }
    }
  }

  return (
    <Container>
      <Row>
        <Col col="12">
          {props.bloc.title && (
            <TagRender bloc="title" tag={props.bloc.title_tag} content={props.bloc.title} />
          )}

          {props.bloc.subtitle && (
            <TagRender bloc="subtitle" tag={props.bloc.subtitle_tag} content={props.bloc.subtitle} />
          )}

          {(props.bloc.button_primary || props.bloc.button_secondary) && (
            <Action>
              {props.bloc.button_primary && (
                <Button content={props.bloc.button_primary} popup={props.site.popup} />
              )}
              {props.bloc.button_secondary && (
                <Button content={props.bloc.button_secondary} popup={props.site.popup} />
              )}
            </Action>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default Title;
