import React from "react";

import { Container, Row, Col } from 'styled-bootstrap-grid';

import styled from 'styled-components'

const GridItem = styled.div`
  padding-bottom: 32px;
`;

const GridImage = styled.img`
  height: auto;
  width: 100%;
`;

const ImagesGrid = ({ props }) => {

  function renderGrid(param) {
    switch(param) {
      case 'three':
        return '4';
      case 'six':
        return '2';
      default:
        return '3';
    }
  }

  return (
    <Container>
      <Row>
      {props.images.map((item, index) => {
        return(
          <Col col={6} md={renderGrid(props.items_by_line)} key={item.id}>
            <GridItem>
              <a href={item.link}>
                <GridImage src={process.env.NODE_ENV !== "development" ? item.image.url : process.env.REACT_APP_BACKEND_URL + item.image.url} alt={item.alt_text} />
              </a>
            </GridItem>
          </Col>
        )
      })}
      </Row>
    </Container>
  );
};

export default ImagesGrid;
