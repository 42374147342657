import React from "react";
import styled from 'styled-components';
import { Container, Row, Col } from 'styled-bootstrap-grid';

import Button from "./button";

const Action = styled.div `
  text-align: center;
`;

const SectionButton = ( props ) => {

  return (
    <Container>
      <Row>
        <Col col="12">

          <Action>
            {props.bloc.button && (
              <Button content={props.bloc.button} popup={props.site.popup} />
            )}
          </Action>

        </Col>
      </Row>
    </Container>
  );
};

export default SectionButton;
