import React from "react";
// import styled from 'styled-components'

// const Section = styled.section`
//   background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
//   background-size: 400% 400%;
//   height: 100vh;
//   width: 100%;
//
//   animation: gradient 15s ease infinite;
//
//   @keyframes gradient {
//     0% {
//       background-position: 0% 50%;
//     }
//     50% {
//       background-position: 100% 50%;
//     }
//     100% {
//       background-position: 0% 50%;
//     }
//   }
// `;

const Loading = ({ props }) => {

  return (
    <div></div>
  );
};

export default Loading;
