import React from "react";
import styled from 'styled-components'

import { Container, Row, Col } from 'styled-bootstrap-grid';
import Button from "./button";

const Icon = styled.img`
  height: 140px;
  margin-bottom: 1.5rem;
  width: 140px;
`;

const Title = styled.div`
  margin-bottom: 3rem;

  font-size: 2rem;
  line-height: 3rem;
`;

const Column = styled.div`
  margin-bottom: 8rem;

  text-align: center;

  @media screen and (min-width: 768px) {
    margin-bottom: 0;
  }
`;

const List = styled.ul`
  margin-bottom: 1.75rem;
  padding-bottom: 1.75rem;

  border-bottom: 0.375rem solid #000;
  border-color: ${ props => props.separatorColor || "#000" };

  :last-of-type {
    border: 0 !important;
  }
`;

const ListItem = styled.li`
  background-color: ${ props => props.bgColor || "#fff" };
  margin-bottom: 1px;
  min-height: 2.75rem;

  color: ${ props => props.txtColor || "#000" };
  font-size: 0.825rem;
  line-height: 2.5rem;

  span {
    display: inline-block;

    line-height: 1rem;
    vertical-align: middle;
  }
`;

const AmountLineOne = styled.div`
  font-size: 1.75rem;
  font-weight: bold;
  line-height: 3rem;
`;

const AmountLineTwo = styled.div`
  color: #B2C82C;
  font-size: 1.25rem;
  line-height: 3rem;
`;

const ButtonWrapper = styled.div`
  margin-top: 1.5rem;
`;

const PricingTable = ( props ) => {

  return (
    <Container>
      <Row>
        {props.bloc.columns.map((column, index) => (
          <Col col={12} md={4} key={index}>
            <Column>
              {column.icon && (
                <Icon src={process.env.NODE_ENV !== "development" ? column.icon.url : process.env.REACT_APP_BACKEND_URL + column.icon.url} alt="" />
              )}
              {column.Title && (
                <Title dangerouslySetInnerHTML={{__html: column.Title}} />
              )}

              {column.blocs.map((bloc, index) =>  (
                <List key={index} separatorColor={column.blocs_separator_color}>
                  {bloc.lines.map((line, index) =>  (
                    <ListItem
                      key={index}
                      bgColor={line.background_color}
                      txtColor={line.text_color}
                    >
                      <span>{line.feature}</span>
                    </ListItem>
                  ))}
                </List>
              ))}

              {column.amount_line_one && (
                <AmountLineOne>{column.amount_line_one}</AmountLineOne>
              )}
              {column.amount_line_two && (
                <AmountLineTwo>{column.amount_line_two}</AmountLineTwo>
              )}

              {column.button && (
                <ButtonWrapper>
                  <Button content={column.button} popup={props.site.popup} />
                </ButtonWrapper>
              )}

            </Column>
          </Col>
        ))}
      </Row>
    </Container>
  );
};

export default PricingTable;
